<template lang="pug">
.v-available.available(v-if="data")
	.available__header(id="map")
		h2.available__title.h2(v-if="data.title") {{data.title}}
		.available__filters(v-if="data.filters")
			.available__filter(v-for="(filter, filterInd) in data.filters" :key="filterInd")
				v-field.available__filter-field(:field="filter"
						appearance="form"
						v-model="filter.value"
						@update:model-value="filterChanged(filter, $event)")
		.available__extra-filter
				.available__tabs(v-if="data.tabs")
					button.section__tab-btn.btn.btn--md(v-for="(tab, index) in data.tabs"
							:key="index"
							@click.stop="setActiveTab(tab.tabId)"
							:class="{'btn--primary': tab.tabId === acviveTabId}")
						| {{ tab.text }}
				v-field.available__tabs-filter(v-if="data.sort" :field="data.sort" @update:model-value="filterChanged(data.sort, $event)")
				v-field.available__tabs-search(v-if="data.search" :field="data.search" @update:model-value="filterChanged(data.search, $event)")
	.available__wrap(ref="map" v-if="data.items && acviveTabId === 'map'" :class="{'available__wrap--map': acviveTabId === 'map'}")
		v-map.available__map(:markers="data.items" :coords="data.mapCenterCoords" :zoom="data.mapZoom" @click-marker="clickMarkerHandler" clusters ref="map")
	.available__wrap(ref="list" v-if="data.items && acviveTabId === 'list'" :class="{'available__wrap--list': acviveTabId === 'list'}")
		v-available-table.available__table(:data-source="data")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import scrollLock from "scroll-lock";
import VField from "./v-field.vue";

export default {
	components: {VField},
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			acviveTabId: null,
			localFilter: null
			// timerId: null
		};
	},

	methods: {
		initFilter() {
			let result = {};
			this.data.filters.forEach(filter => {
				result[filter.name] = filter.value;
			});

			result.sort = this.data.sort.value;
			result.search = this.data.search.value;

			this.localFilter = result;
			return result;
		},

		lockScrolling() {
			if (this.$el.closest(".modal")) {
				scrollLock.clearQueueScrollLocks();
				let $map = this.$refs.map;
				let $list = this.$refs.list;
				if ($map) {
					scrollLock.disablePageScroll($map);
				} else if ($list) {
					scrollLock.disablePageScroll($list);
				}
			}
		},

		setActiveTab(id) {
			this.acviveTabId = id;
			this.$nextTick(() => {
				this.lockScrolling();
			});
		},

		// getFiltersValue() {
		// 	let result = {};
		// 	console.log('getFiltersValuem', this.table, this.table.filters, this.table.filters.length)
		// 	if (this.table && this.table.filters && this.table.filters.length > 0) {
		// 		this.table.filters.forEach(filter => {
		// 			console.log('filter', filter)
		// 			result[filter.name] = {
		// 				value: filter.value,
		// 				type: filter.type
		// 			};
		// 		});
		// 	}
		// 	return result;
		// },
		//
		// filterChanged() {
		// 	clearTimeout(this.timerId);
		// 	this.timerId = setTimeout(() => {
		// 		this.dataLoad({
		// 			url: this.data.remote,
		// 			delay: true,
		// 			data: {
		// 				filters: this.getFiltersValue()
		// 			}
		// 		});
		// 	}, 1500)
		// },

		filterChanged(filter, value)  {
			if (!this.localFilter || typeof this.localFilter !== 'object' || Array.isArray(this.localFilter)) {
				this.localFilter = {};
			}

			if (filter.name === 'search' || filter.name === 'sort') {
				this.localFilter[filter.name] = value;
			} else {
				this.localFilter = { ...this.localFilter, [filter.name]: value };
			}

			console.log("localFilter.sort", this.localFilter.sort);
			console.log("Тип localFilter.sort:", typeof this.localFilter.sort);

			this.dataLoad({
				url: this.data.remote,
				delay: true,
				data: {
					filters: this.getCleanFilters(),
					sort: this.localFilter.sort || null,
					search: this.localFilter.search || null
				}
			});
		},


		dataSet(data) {
			this.data = data;
			this.acviveTabId = this.data?.tabs.find(tab => tab.active === true).tabId || null
			this.localFilter = this.initFilter();
			this.$nextTick(() => {
				this.lockScrolling();
			});
		},

		getCleanFilters() {
			const { sort, search, ...filters } = this.localFilter;
			return filters;
		}
	},

	unmounted() {
		scrollLock.enablePageScroll(document.querySelectorAll('.available__wrap'));
	}
};
</script>
